html,
body,
#main {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
}
body,
input,
textarea,
select,
button {
  font-size: 12px;
  font-family: sans-serif;
}
#__framer-badge-container {
  position: fixed;
  bottom: 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: right;
  pointer-events: none;
  z-index: 314159;
}
.__framer-badge {
  pointer-events: auto;
}
@font-face {
  font-family: Schibsted Grotesk;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9Iayoxdg.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Schibsted Grotesk;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9Gayo.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: Schibsted Grotesk;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9Iayoxdg.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Schibsted Grotesk;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/schibstedgrotesk/v3/Jqz55SSPQuCQF3t8uOwiUL-taUTtap9Gayo.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: Orelega One;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZFtw3kYoaQ.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: Orelega One;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZFt53kYoaQ.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: Orelega One;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZFtz3kYoaQ.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: Orelega One;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/orelegaone/v10/3qTpojOggD2XtAdFb-QXZFt93kY.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: Schibsted Grotesk Placeholder;
  ascent-override: 92.54%;
  descent-override: 24.43%;
  line-gap-override: 0%;
  size-adjust: 105.53%;
  src: local("Arial");
}
@font-face {
  font-family: Inter Placeholder;
  ascent-override: 90%;
  descent-override: 22.43%;
  line-gap-override: 0%;
  size-adjust: 107.64%;
  src: local("Arial");
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-ext-BHDA65O4.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-Q5IXHU2O.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.greek-ext-VVOQA2NE.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.greek-E3WNEUH6.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.latin-ext-5RC4NRHN.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.latin-GJ7CRGHG.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-Thin.vietnamese-Z3Y7DPWO.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-ext-7NWKXNAT.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-IBWUD6RJ.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ext-HUMDTRBU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ZLLHEEN3.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-ext-72JE5FGU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-2JHDAFAQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLight.vietnamese-VKRCA4VC.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.cyrillic-ext-G3OTPKE4.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.cyrillic-JO7ZJTP6.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.greek-ext-N63XCCK3.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.greek-5GVUXSXZ.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.latin-ext-RREJIMQ3.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.latin-EKF76FXR.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-Light.vietnamese-GVC2UOFS.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-ext-C7KWUKA7.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-JWV7SOZ6.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.greek-ext-FBKSFTSU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.greek-EQ3PSENU.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.latin-ext-ULRSO3ZR.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.latin-RDYY2AG2.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBold.vietnamese-ESQNSEQ3.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-ext-XOTVL7ZR.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-6LOMBC2V.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.greek-ext-WXWSJXLB.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.greek-YRST7ODZ.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.latin-ext-BASA5UL3.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.latin-UCM45LQF.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-Bold.vietnamese-OEVJMXEP.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-ext-7Q6SVIPE.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-JSLPE6KW.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-ext-6OYGJJV7.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-SHW2FPC4.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-ext-A5DUFOP6.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-OW4UGSRU.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBold.vietnamese-IBBC7NGV.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.cyrillic-ext-TU4ITVTR.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.cyrillic-JX7CGTYD.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.greek-ext-LS3GCBFI.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.greek-ZWCJHBP5.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.latin-ext-BZLEUMX6.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.latin-TETRYDF7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-Black.vietnamese-RXQCC3EJ.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-ext-2RGKWUBV.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-TDYIP5HV.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-ext-WR4TIDYZ.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-V3WZMSP7.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-ext-TXFTJONQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-RVEBKP6O.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(https://app.framerstatic.com/Inter-ThinItalic.vietnamese-WCBPP4MD.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-ext-OVCHMVPD.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-BRDZE5UH.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-ext-YV64YFFH.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-EJVCLASM.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-ext-2MHTM56A.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-JB3CJMMM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.vietnamese-3EJ3IQYS.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-ext-C2S5XS3D.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-E7CYPW5D.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.greek-ext-ROSAFPGE.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.greek-PAWWH37Z.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.latin-ext-N2Z67Z45.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.latin-SLSTLWEU.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(https://app.framerstatic.com/Inter-LightItalic.vietnamese-RLGM2D3Y.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-ext-YDGMJOJO.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-BFOVMAQB.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.greek-ext-4KOU3AHC.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.greek-OJTBJNE6.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.latin-ext-H4B22QN6.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.latin-2DWX32EN.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(https://app.framerstatic.com/Inter-Italic.vietnamese-TYMT6CKW.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ext-QYBZQ2NF.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ZHAJHZCC.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-ext-W5ABYGZR.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-RGNSYVNV.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-ext-7DZEPSAS.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-SKPQAMBJ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(https://app.framerstatic.com/Inter-MediumItalic.vietnamese-23WIFZV7.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-ext-MEHHCDC3.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-YACNRNDE.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-ext-GFL7KADI.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-5W77OPRT.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-ext-OYJJ2W6R.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-KBLJMBDH.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.vietnamese-5ZFOV65G.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-ext-PEYDHC3S.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-7EIL6JWG.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-ext-3DJOYQMH.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-TJBTLTT7.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-ext-FVPCPRBJ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-5ZFQS4XK.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(https://app.framerstatic.com/Inter-BoldItalic.vietnamese-W2625PGF.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ext-ACWDZ3VD.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ZKBSDAI2.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-ext-3CY5DPTP.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-YL5CC63W.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-ext-7IZFJI4D.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-O5HH4IX3.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.vietnamese-UW3XUJOD.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-ext-TRM4ITYR.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-FPHIQVZS.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-ext-JTGUUSP5.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-LUNA3RFO.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-ext-KU7ICFYH.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-FRVXWQSB.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: block;
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(https://app.framerstatic.com/Inter-BlackItalic.vietnamese-2Q7MQKJX.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
[data-framer-component-type] {
  position: absolute;
}
[data-framer-component-type="Text"] {
  cursor: inherit;
}
[data-framer-component-text-autosized] * {
  white-space: pre;
}
[data-framer-component-type="Text"] > * {
  text-align: var(--framer-text-alignment, start);
}
[data-framer-component-type="Text"] span span,
[data-framer-component-type="Text"] p span,
[data-framer-component-type="Text"] h1 span,
[data-framer-component-type="Text"] h2 span,
[data-framer-component-type="Text"] h3 span,
[data-framer-component-type="Text"] h4 span,
[data-framer-component-type="Text"] h5 span,
[data-framer-component-type="Text"] h6 span {
  display: block;
}
[data-framer-component-type="Text"] span span span,
[data-framer-component-type="Text"] p span span,
[data-framer-component-type="Text"] h1 span span,
[data-framer-component-type="Text"] h2 span span,
[data-framer-component-type="Text"] h3 span span,
[data-framer-component-type="Text"] h4 span span,
[data-framer-component-type="Text"] h5 span span,
[data-framer-component-type="Text"] h6 span span {
  display: unset;
}
[data-framer-component-type="Text"] div div span,
[data-framer-component-type="Text"] a div span,
[data-framer-component-type="Text"] span span span,
[data-framer-component-type="Text"] p span span,
[data-framer-component-type="Text"] h1 span span,
[data-framer-component-type="Text"] h2 span span,
[data-framer-component-type="Text"] h3 span span,
[data-framer-component-type="Text"] h4 span span,
[data-framer-component-type="Text"] h5 span span,
[data-framer-component-type="Text"] h6 span span,
[data-framer-component-type="Text"] a {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: min(
    calc(var(--framer-font-weight-increase, 0) + var(--font-weight, 400)),
    900
  );
  color: var(--text-color);
  letter-spacing: var(--letter-spacing);
  font-size: var(--font-size);
  text-transform: var(--text-transform);
  text-decoration: var(--text-decoration);
  line-height: var(--line-height);
}
[data-framer-component-type="Text"] div div span,
[data-framer-component-type="Text"] a div span,
[data-framer-component-type="Text"] span span span,
[data-framer-component-type="Text"] p span span,
[data-framer-component-type="Text"] h1 span span,
[data-framer-component-type="Text"] h2 span span,
[data-framer-component-type="Text"] h3 span span,
[data-framer-component-type="Text"] h4 span span,
[data-framer-component-type="Text"] h5 span span,
[data-framer-component-type="Text"] h6 span span,
[data-framer-component-type="Text"] a {
  --font-family: var(--framer-font-family);
  --font-style: var(--framer-font-style);
  --font-weight: var(--framer-font-weight);
  --text-color: var(--framer-text-color);
  --letter-spacing: var(--framer-letter-spacing);
  --font-size: var(--framer-font-size);
  --text-transform: var(--framer-text-transform);
  --text-decoration: var(--framer-text-decoration);
  --line-height: var(--framer-line-height);
}
[data-framer-component-type="Text"] a,
[data-framer-component-type="Text"] a div span,
[data-framer-component-type="Text"] a span span span,
[data-framer-component-type="Text"] a p span span,
[data-framer-component-type="Text"] a h1 span span,
[data-framer-component-type="Text"] a h2 span span,
[data-framer-component-type="Text"] a h3 span span,
[data-framer-component-type="Text"] a h4 span span,
[data-framer-component-type="Text"] a h5 span span,
[data-framer-component-type="Text"] a h6 span span {
  --font-family: var(--framer-link-font-family, var(--framer-font-family));
  --font-style: var(--framer-link-font-style, var(--framer-font-style));
  --font-weight: var(--framer-link-font-weight, var(--framer-font-weight));
  --text-color: var(--framer-link-text-color, var(--framer-text-color));
  --font-size: var(--framer-link-font-size, var(--framer-font-size));
  --text-transform: var(
    --framer-link-text-transform,
    var(--framer-text-transform)
  );
  --text-decoration: var(
    --framer-link-text-decoration,
    var(--framer-text-decoration)
  );
}
[data-framer-component-type="Text"] a:hover,
[data-framer-component-type="Text"] a div span:hover,
[data-framer-component-type="Text"] a span span span:hover,
[data-framer-component-type="Text"] a p span span:hover,
[data-framer-component-type="Text"] a h1 span span:hover,
[data-framer-component-type="Text"] a h2 span span:hover,
[data-framer-component-type="Text"] a h3 span span:hover,
[data-framer-component-type="Text"] a h4 span span:hover,
[data-framer-component-type="Text"] a h5 span span:hover,
[data-framer-component-type="Text"] a h6 span span:hover {
  --font-family: var(
    --framer-link-hover-font-family,
    var(--framer-link-font-family, var(--framer-font-family))
  );
  --font-style: var(
    --framer-link-hover-font-style,
    var(--framer-link-font-style, var(--framer-font-style))
  );
  --font-weight: var(
    --framer-link-hover-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight))
  );
  --text-color: var(
    --framer-link-hover-text-color,
    var(--framer-link-text-color, var(--framer-text-color))
  );
  --font-size: var(
    --framer-link-hover-font-size,
    var(--framer-link-font-size, var(--framer-font-size))
  );
  --text-transform: var(
    --framer-link-hover-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform))
  );
  --text-decoration: var(
    --framer-link-hover-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration))
  );
}
[data-framer-component-type="Text"].isCurrent a,
[data-framer-component-type="Text"].isCurrent a div span,
[data-framer-component-type="Text"].isCurrent a span span span,
[data-framer-component-type="Text"].isCurrent a p span span,
[data-framer-component-type="Text"].isCurrent a h1 span span,
[data-framer-component-type="Text"].isCurrent a h2 span span,
[data-framer-component-type="Text"].isCurrent a h3 span span,
[data-framer-component-type="Text"].isCurrent a h4 span span,
[data-framer-component-type="Text"].isCurrent a h5 span span,
[data-framer-component-type="Text"].isCurrent a h6 span span {
  --font-family: var(
    --framer-link-current-font-family,
    var(--framer-link-font-family, var(--framer-font-family))
  );
  --font-style: var(
    --framer-link-current-font-style,
    var(--framer-link-font-style, var(--framer-font-style))
  );
  --font-weight: var(
    --framer-link-current-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight))
  );
  --text-color: var(
    --framer-link-current-text-color,
    var(--framer-link-text-color, var(--framer-text-color))
  );
  --font-size: var(
    --framer-link-current-font-size,
    var(--framer-link-font-size, var(--framer-font-size))
  );
  --text-transform: var(
    --framer-link-current-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform))
  );
  --text-decoration: var(
    --framer-link-current-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration))
  );
}
p.framer-text,
div.framer-text,
h1.framer-text,
h2.framer-text,
h3.framer-text,
h4.framer-text,
h5.framer-text,
h6.framer-text,
ol.framer-text,
ul.framer-text {
  margin: 0;
  padding: 0;
}
p.framer-text,
div.framer-text,
h1.framer-text,
h2.framer-text,
h3.framer-text,
h4.framer-text,
h5.framer-text,
h6.framer-text,
li.framer-text,
ol.framer-text,
ul.framer-text,
span.framer-text:not([data-text-fill]) {
  font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
  font-style: var(--framer-font-style, normal);
  font-weight: var(--framer-font-weight, 400);
  color: var(--framer-text-color, #000);
  font-size: calc(
    var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1)
  );
  letter-spacing: var(--framer-letter-spacing, 0);
  text-transform: var(--framer-text-transform, none);
  text-decoration: var(--framer-text-decoration, none);
  line-height: var(--framer-line-height, 1.2em);
  text-align: var(--framer-text-alignment, start);
}
strong.framer-text {
  font-weight: bolder;
}
em.framer-text {
  font-style: italic;
}
p.framer-text:not(:first-child),
div.framer-text:not(:first-child),
h1.framer-text:not(:first-child),
h2.framer-text:not(:first-child),
h3.framer-text:not(:first-child),
h4.framer-text:not(:first-child),
h5.framer-text:not(:first-child),
h6.framer-text:not(:first-child),
ol.framer-text:not(:first-child),
ul.framer-text:not(:first-child),
.framer-image.framer-text:not(:first-child) {
  margin-top: var(--framer-paragraph-spacing, 0);
}
li.framer-text > ul.framer-text:nth-child(2),
li.framer-text > ol.framer-text:nth-child(2) {
  margin-top: 0;
}
.framer-text[data-text-fill] {
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: max(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / 2));
  margin: min(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / -2));
}
code.framer-text,
code.framer-text span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-code-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
  color: var(--framer-code-text-color, var(--framer-text-color, #000));
  font-size: calc(
    var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1)
  );
  letter-spacing: var(--framer-letter-spacing, 0);
  line-height: var(--framer-line-height, 1.2em);
}
a.framer-text,
a.framer-text span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-link-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
  color: var(--framer-link-text-color, var(--framer-text-color, #000));
  font-size: calc(
    var(--framer-link-font-size, var(--framer-font-size, 16px)) *
      var(--framer-font-size-scale, 1)
  );
  text-transform: var(
    --framer-link-text-transform,
    var(--framer-text-transform, none)
  );
  text-decoration: var(
    --framer-link-text-decoration,
    var(--framer-text-decoration, none)
  );
  cursor: var(--framer-custom-cursors, pointer);
}
code.framer-text a.framer-text,
code.framer-text a.framer-text span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-code-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
  color: var(
    --framer-link-text-color,
    var(--framer-code-text-color, var(--framer-text-color, #000))
  );
  font-size: calc(
    var(--framer-link-font-size, var(--framer-font-size, 16px)) *
      var(--framer-font-size-scale, 1)
  );
}
a.framer-text:hover,
a.framer-text:hover span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-link-hover-font-family,
    var(
      --framer-link-font-family,
      var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
    )
  );
  font-style: var(
    --framer-link-hover-font-style,
    var(--framer-link-font-style, var(--framer-font-style, normal))
  );
  font-weight: var(
    --framer-link-hover-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight, 400))
  );
  color: var(
    --framer-link-hover-text-color,
    var(--framer-link-text-color, var(--framer-text-color, #000))
  );
  font-size: calc(
    var(
        --framer-link-hover-font-size,
        var(--framer-link-font-size, var(--framer-font-size, 16px))
      ) * var(--framer-font-size-scale, 1)
  );
  text-transform: var(
    --framer-link-hover-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform, none))
  );
  text-decoration: var(
    --framer-link-hover-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration, none))
  );
}
code.framer-text a.framer-text:hover,
code.framer-text a.framer-text:hover span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-code-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
  color: var(
    --framer-link-hover-text-color,
    var(
      --framer-link-text-color,
      var(--framer-code-text-color, var(--framer-text-color, #000))
    )
  );
  font-size: calc(
    var(
        --framer-link-hover-font-size,
        var(--framer-link-font-size, var(--framer-font-size, 16px))
      ) * var(--framer-font-size-scale, 1)
  );
}
a.framer-text[data-framer-page-link-current],
a.framer-text[data-framer-page-link-current]
  span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-link-current-font-family,
    var(
      --framer-link-font-family,
      var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
    )
  );
  font-style: var(
    --framer-link-current-font-style,
    var(--framer-link-font-style, var(--framer-font-style, normal))
  );
  font-weight: var(
    --framer-link-current-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight, 400))
  );
  color: var(
    --framer-link-current-text-color,
    var(--framer-link-text-color, var(--framer-text-color, #000))
  );
  font-size: calc(
    var(
        --framer-link-current-font-size,
        var(--framer-link-font-size, var(--framer-font-size, 16px))
      ) * var(--framer-font-size-scale, 1)
  );
  text-transform: var(
    --framer-link-current-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform, none))
  );
  text-decoration: var(
    --framer-link-current-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration, none))
  );
}
code.framer-text a.framer-text[data-framer-page-link-current],
code.framer-text
  a.framer-text[data-framer-page-link-current]
  span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-code-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
  color: var(
    --framer-link-current-text-color,
    var(
      --framer-link-text-color,
      var(--framer-code-text-color, var(--framer-text-color, #000))
    )
  );
  font-size: calc(
    var(
        --framer-link-current-font-size,
        var(--framer-link-font-size, var(--framer-font-size, 16px))
      ) * var(--framer-font-size-scale, 1)
  );
}
a.framer-text[data-framer-page-link-current]:hover,
a.framer-text[data-framer-page-link-current]:hover
  span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-link-hover-font-family,
    var(
      --framer-link-current-font-family,
      var(
        --framer-link-font-family,
        var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
      )
    )
  );
  font-style: var(
    --framer-link-hover-font-style,
    var(
      --framer-link-current-font-style,
      var(--framer-link-font-style, var(--framer-font-style, normal))
    )
  );
  font-weight: var(
    --framer-link-hover-font-weight,
    var(
      --framer-link-current-font-weight,
      var(--framer-link-font-weight, var(--framer-font-weight, 400))
    )
  );
  color: var(
    --framer-link-hover-text-color,
    var(
      --framer-link-current-text-color,
      var(--framer-link-text-color, var(--framer-text-color, #000))
    )
  );
  font-size: calc(
    var(
        --framer-link-hover-font-size,
        var(
          --framer-link-current-font-size,
          var(--framer-link-font-size, var(--framer-font-size, 16px))
        )
      ) * var(--framer-font-size-scale, 1)
  );
  text-transform: var(
    --framer-link-hover-text-transform,
    var(
      --framer-link-current-text-transform,
      var(--framer-link-text-transform, var(--framer-text-transform, none))
    )
  );
  text-decoration: var(
    --framer-link-hover-text-decoration,
    var(
      --framer-link-current-text-decoration,
      var(--framer-link-text-decoration, var(--framer-text-decoration, none))
    )
  );
}
code.framer-text a.framer-text[data-framer-page-link-current]:hover,
code.framer-text
  a.framer-text[data-framer-page-link-current]:hover
  span.framer-text:not([data-text-fill]) {
  font-family: var(
    --framer-code-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
  color: var(
    --framer-link-hover-text-color,
    var(
      --framer-link-current-text-color,
      var(
        --framer-link-text-color,
        var(--framer-code-text-color, var(--framer-text-color, #000))
      )
    )
  );
  font-size: calc(
    var(
        --framer-link-hover-font-size,
        var(
          --framer-link-current-font-size,
          var(--framer-link-font-size, var(--framer-font-size, 16px))
        )
      ) * var(--framer-font-size-scale, 1)
  );
}
.framer-image.framer-text {
  display: block;
  max-width: 100%;
  height: auto;
}
.text-styles-preset-reset.framer-text {
  --framer-font-family: Inter, Inter Placeholder, sans-serif;
  --framer-font-style: normal;
  --framer-font-weight: 500;
  --framer-text-color: #000;
  --framer-font-size: 16px;
  --framer-letter-spacing: 0;
  --framer-text-transform: none;
  --framer-text-decoration: none;
  --framer-line-height: 1.2em;
  --framer-text-alignment: start;
}
ul.framer-text,
ol.framer-text {
  display: table;
  width: 100%;
}
li.framer-text {
  display: table-row;
  counter-increment: list-item;
  list-style: none;
}
ol.framer-text > li.framer-text:before {
  display: table-cell;
  width: 2.25ch;
  box-sizing: border-box;
  padding-inline-end: 0.75ch;
  content: counter(list-item) ".";
  white-space: nowrap;
}
ul.framer-text > li.framer-text:before {
  display: table-cell;
  width: 2.25ch;
  box-sizing: border-box;
  padding-inline-end: 0.75ch;
  content: "\2022";
}
.framer-text-module[style*="aspect-ratio"] > :first-child {
  width: 100%;
}
@supports not (aspect-ratio: 1) {
  .framer-text-module[style*="aspect-ratio"] {
    position: relative;
  }
}
@supports not (aspect-ratio: 1) {
  .framer-text-module[style*="aspect-ratio"]:before {
    content: "";
    display: block;
    padding-bottom: calc(100% / calc(var(--aspect-ratio)));
  }
}
@supports not (aspect-ratio: 1) {
  .framer-text-module[style*="aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
[data-framer-component-type="DeprecatedRichText"] {
  cursor: inherit;
}
[data-framer-component-type="DeprecatedRichText"] .text-styles-preset-reset {
  --framer-font-family: Inter, Inter Placeholder, sans-serif;
  --framer-font-style: normal;
  --framer-font-weight: 500;
  --framer-text-color: #000;
  --framer-font-size: 16px;
  --framer-letter-spacing: 0;
  --framer-text-transform: none;
  --framer-text-decoration: none;
  --framer-line-height: 1.2em;
  --framer-text-alignment: start;
}
[data-framer-component-type="DeprecatedRichText"] p,
[data-framer-component-type="DeprecatedRichText"] div,
[data-framer-component-type="DeprecatedRichText"] h1,
[data-framer-component-type="DeprecatedRichText"] h2,
[data-framer-component-type="DeprecatedRichText"] h3,
[data-framer-component-type="DeprecatedRichText"] h4,
[data-framer-component-type="DeprecatedRichText"] h5,
[data-framer-component-type="DeprecatedRichText"] h6 {
  margin: 0;
  padding: 0;
}
[data-framer-component-type="DeprecatedRichText"] p,
[data-framer-component-type="DeprecatedRichText"] div,
[data-framer-component-type="DeprecatedRichText"] h1,
[data-framer-component-type="DeprecatedRichText"] h2,
[data-framer-component-type="DeprecatedRichText"] h3,
[data-framer-component-type="DeprecatedRichText"] h4,
[data-framer-component-type="DeprecatedRichText"] h5,
[data-framer-component-type="DeprecatedRichText"] h6,
[data-framer-component-type="DeprecatedRichText"] li,
[data-framer-component-type="DeprecatedRichText"] ol,
[data-framer-component-type="DeprecatedRichText"] ul,
[data-framer-component-type="DeprecatedRichText"] span:not([data-text-fill]) {
  font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
  font-style: var(--framer-font-style, normal);
  font-weight: var(--framer-font-weight, 400);
  color: var(--framer-text-color, #000);
  font-size: var(--framer-font-size, 16px);
  letter-spacing: var(--framer-letter-spacing, 0);
  text-transform: var(--framer-text-transform, none);
  text-decoration: var(--framer-text-decoration, none);
  line-height: var(--framer-line-height, 1.2em);
  text-align: var(--framer-text-alignment, start);
}
[data-framer-component-type="DeprecatedRichText"] p:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] div:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h1:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h2:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h3:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h4:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h5:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] h6:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] ol:not(:first-child),
[data-framer-component-type="DeprecatedRichText"] ul:not(:first-child),
[data-framer-component-type="DeprecatedRichText"]
  .framer-image:not(:first-child) {
  margin-top: var(--framer-paragraph-spacing, 0);
}
[data-framer-component-type="DeprecatedRichText"] span[data-text-fill] {
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
[data-framer-component-type="DeprecatedRichText"] a,
[data-framer-component-type="DeprecatedRichText"] a span:not([data-text-fill]) {
  font-family: var(
    --framer-link-font-family,
    var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
  );
  font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
  font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
  color: var(--framer-link-text-color, var(--framer-text-color, #000));
  font-size: var(--framer-link-font-size, var(--framer-font-size, 16px));
  text-transform: var(
    --framer-link-text-transform,
    var(--framer-text-transform, none)
  );
  text-decoration: var(
    --framer-link-text-decoration,
    var(--framer-text-decoration, none)
  );
}
[data-framer-component-type="DeprecatedRichText"] a:hover,
[data-framer-component-type="DeprecatedRichText"]
  a:hover
  span:not([data-text-fill]) {
  font-family: var(
    --framer-link-hover-font-family,
    var(
      --framer-link-font-family,
      var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
    )
  );
  font-style: var(
    --framer-link-hover-font-style,
    var(--framer-link-font-style, var(--framer-font-style, normal))
  );
  font-weight: var(
    --framer-link-hover-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight, 400))
  );
  color: var(
    --framer-link-hover-text-color,
    var(--framer-link-text-color, var(--framer-text-color, #000))
  );
  font-size: var(
    --framer-link-hover-font-size,
    var(--framer-link-font-size, var(--framer-font-size, 16px))
  );
  text-transform: var(
    --framer-link-hover-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform, none))
  );
  text-decoration: var(
    --framer-link-hover-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration, none))
  );
}
a[data-framer-page-link-current],
a[data-framer-page-link-current] span:not([data-text-fill]) {
  font-family: var(
    --framer-link-current-font-family,
    var(
      --framer-link-font-family,
      var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
    )
  );
  font-style: var(
    --framer-link-current-font-style,
    var(--framer-link-font-style, var(--framer-font-style, normal))
  );
  font-weight: var(
    --framer-link-current-font-weight,
    var(--framer-link-font-weight, var(--framer-font-weight, 400))
  );
  color: var(
    --framer-link-current-text-color,
    var(--framer-link-text-color, var(--framer-text-color, #000))
  );
  font-size: var(
    --framer-link-current-font-size,
    var(--framer-link-font-size, var(--framer-font-size, 16px))
  );
  text-transform: var(
    --framer-link-current-text-transform,
    var(--framer-link-text-transform, var(--framer-text-transform, none))
  );
  text-decoration: var(
    --framer-link-current-text-decoration,
    var(--framer-link-text-decoration, var(--framer-text-decoration, none))
  );
}
a[data-framer-page-link-current]:hover,
a[data-framer-page-link-current]:hover span:not([data-text-fill]) {
  font-family: var(
    --framer-link-hover-font-family,
    var(
      --framer-link-current-font-family,
      var(
        --framer-link-font-family,
        var(--framer-font-family, Inter, Inter Placeholder, sans-serif)
      )
    )
  );
  font-style: var(
    --framer-link-hover-font-style,
    var(
      --framer-link-current-font-style,
      var(--framer-link-font-style, var(--framer-font-style, normal))
    )
  );
  font-weight: var(
    --framer-link-hover-font-weight,
    var(
      --framer-link-current-font-weight,
      var(--framer-link-font-weight, var(--framer-font-weight, 400))
    )
  );
  color: var(
    --framer-link-hover-text-color,
    var(
      --framer-link-current-text-color,
      var(--framer-link-text-color, var(--framer-text-color, #000))
    )
  );
  font-size: var(
    --framer-link-hover-font-size,
    var(
      --framer-link-current-font-size,
      var(--framer-link-font-size, var(--framer-font-size, 16px))
    )
  );
  text-transform: var(
    --framer-link-hover-text-transform,
    var(
      --framer-link-current-text-transform,
      var(--framer-link-text-transform, var(--framer-text-transform, none))
    )
  );
  text-decoration: var(
    --framer-link-hover-text-decoration,
    var(
      --framer-link-current-text-decoration,
      var(--framer-link-text-decoration, var(--framer-text-decoration, none))
    )
  );
}
[data-framer-component-type="DeprecatedRichText"] strong {
  font-weight: bolder;
}
[data-framer-component-type="DeprecatedRichText"] em {
  font-style: italic;
}
[data-framer-component-type="DeprecatedRichText"] .framer-image {
  display: block;
  max-width: 100%;
  height: auto;
}
[data-framer-component-type="DeprecatedRichText"] ul,
[data-framer-component-type="DeprecatedRichText"] ol {
  display: table;
  width: 100%;
  padding-left: 0;
  margin: 0;
}
[data-framer-component-type="DeprecatedRichText"] li {
  display: table-row;
  counter-increment: list-item;
  list-style: none;
}
[data-framer-component-type="DeprecatedRichText"] ol > li:before {
  display: table-cell;
  width: 2.25ch;
  box-sizing: border-box;
  padding-right: 0.75ch;
  content: counter(list-item) ".";
  white-space: nowrap;
}
[data-framer-component-type="DeprecatedRichText"] ul > li:before {
  display: table-cell;
  width: 2.25ch;
  box-sizing: border-box;
  padding-right: 0.75ch;
  content: "\2022";
}
:not([data-framer-generated]) > [data-framer-stack-content-wrapper] > *,
:not([data-framer-generated])
  > [data-framer-stack-content-wrapper]
  > [data-framer-component-type],
:not([data-framer-generated])
  > [data-framer-stack-content-wrapper]
  > [data-framer-legacy-stack-gap-enabled]
  > *,
:not([data-framer-generated])
  > [data-framer-stack-content-wrapper]
  > [data-framer-legacy-stack-gap-enabled]
  > [data-framer-component-type] {
  position: relative;
}
.flexbox-gap-not-supported [data-framer-legacy-stack-gap-enabled="true"] > *,
[data-framer-legacy-stack-gap-enabled="true"][data-framer-stack-flexbox-gap="false"] {
  margin-top: calc(var(--stack-gap-y) / 2);
  margin-bottom: calc(var(--stack-gap-y) / 2);
  margin-right: calc(var(--stack-gap-x) / 2);
  margin-left: calc(var(--stack-gap-x) / 2);
}
[data-framer-stack-content-wrapper][data-framer-stack-gap-enabled="true"] {
  row-gap: var(--stack-native-row-gap);
  column-gap: var(--stack-native-column-gap);
}
.flexbox-gap-not-supported
  [data-framer-stack-content-wrapper][data-framer-stack-gap-enabled="true"] {
  row-gap: unset;
  column-gap: unset;
}
.flexbox-gap-not-supported
  [data-framer-stack-direction-reverse="false"]
  [data-framer-legacy-stack-gap-enabled="true"]
  > *:first-child,
[data-framer-stack-direction-reverse="false"]
  [data-framer-legacy-stack-gap-enabled="true"][data-framer-stack-flexbox-gap="false"]
  > *:first-child,
.flexbox-gap-not-supported
  [data-framer-stack-direction-reverse="true"]
  [data-framer-legacy-stack-gap-enabled="true"]
  > *:last-child,
[data-framer-stack-direction-reverse="true"]
  [data-framer-legacy-stack-gap-enabled="true"][data-framer-stack-flexbox-gap="false"]
  > *:last-child {
  margin-top: 0;
  margin-left: 0;
}
.flexbox-gap-not-supported
  [data-framer-stack-direction-reverse="false"]
  [data-framer-legacy-stack-gap-enabled="true"]
  > *:last-child,
[data-framer-stack-direction-reverse="false"]
  [data-framer-legacy-stack-gap-enabled="true"][data-framer-stack-flexbox-gap="false"]
  > *:last-child,
.flexbox-gap-not-supported
  [data-framer-stack-direction-reverse="true"]
  [data-framer-legacy-stack-gap-enabled="true"]
  > *:first-child,
[data-framer-stack-direction-reverse="true"]
  [data-framer-legacy-stack-gap-enabled="true"][data-framer-stack-flexbox-gap="false"]
  > *:first-child {
  margin-right: 0;
  margin-bottom: 0;
}
NavigationContainer [data-framer-component-type="NavigationContainer"] > *,
[data-framer-component-type="NavigationContainer"]
  > [data-framer-component-type] {
  position: relative;
}
[data-framer-component-type="Scroll"]::-webkit-scrollbar {
  display: none;
}
[data-framer-component-type="ScrollContentWrapper"] > * {
  position: relative;
}
[data-framer-component-type="NativeScroll"] {
  -webkit-overflow-scrolling: touch;
}
[data-framer-component-type="NativeScroll"] > * {
  position: relative;
}
[data-framer-component-type="NativeScroll"].direction-both {
  overflow-x: scroll;
  overflow-y: scroll;
}
[data-framer-component-type="NativeScroll"].direction-vertical {
  overflow-x: hidden;
  overflow-y: scroll;
}
[data-framer-component-type="NativeScroll"].direction-horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
}
[data-framer-component-type="NativeScroll"].direction-vertical > * {
  width: 100% !important;
}
[data-framer-component-type="NativeScroll"].direction-horizontal > * {
  height: 100% !important;
}
[data-framer-component-type="NativeScroll"].scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
[data-framer-component-type="PageContentWrapper"] > *,
[data-framer-component-type="PageContentWrapper"]
  > [data-framer-component-type] {
  position: relative;
}
[data-framer-component-type="DeviceComponent"].no-device > * {
  width: 100% !important;
  height: 100% !important;
}
[data-is-present="false"],
[data-is-present="false"] * {
  pointer-events: none !important;
}
[data-framer-cursor="pointer"] {
  cursor: pointer;
}
[data-framer-cursor="grab"] {
  cursor: grab;
}
[data-framer-cursor="grab"]:active {
  cursor: grabbing;
}
[data-framer-component-type="Frame"] *,
[data-framer-component-type="Stack"] * {
  pointer-events: auto;
}
[data-framer-generated] * {
  pointer-events: unset;
}
.svgContainer svg {
  display: block;
}
[data-reset="button"] {
  border-width: 0;
  padding: 0;
  background: none;
}
[data-hide-scrollbars="true"]::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
[data-hide-scrollbars="true"]::-webkit-scrollbar-thumb {
  background: transparent;
}
.framer-ngLLl [data-border="true"]:after {
  content: "";
  border-width: var(--border-top-width, 0) var(--border-right-width, 0)
    var(--border-bottom-width, 0) var(--border-left-width, 0);
  border-color: var(--border-color, none);
  border-style: var(--border-style, none);
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 0;
  border-radius: 0px;
  pointer-events: none;
}
@supports (aspect-ratio: 1) {
  body {
    --framer-aspect-ratio-supported: auto;
  }
}
.framer-body-augiA20Il {
  background: #b9d6f2;
}
.framer-ngLLl .framer-lux5qc {
  display: block;
}
.framer-ngLLl .framer-72rtr7 {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1200px;
}
.framer-ngLLl .framer-1wt6qa {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 100px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-tuvq8b {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.framer-ngLLl .framer-1c2ak4l {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: 1 0 0px;
  height: auto;
  position: relative;
  white-space: pre-wrap;
  width: 1px;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-5welo6,
.framer-ngLLl .framer-1htj277,
.framer-ngLLl .framer-11he7bj,
.framer-ngLLl .framer-16nqora {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  position: relative;
  white-space: pre;
  width: auto;
}
.framer-ngLLl .framer-1bu57fx {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-171542r,
.framer-ngLLl .framer-17p6cqg,
.framer-ngLLl .framer-1pfrn1h {
  align-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: var(--framer-aspect-ratio-supported, 327px);
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1px;
}
.framer-ngLLl .framer-g7los7-container,
.framer-ngLLl .framer-chzr6f-container,
.framer-ngLLl .framer-1n6vhcb-container {
  flex: none;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 4;
}
.framer-ngLLl .framer-13xutd,
.framer-ngLLl .framer-6q8iyr {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-ql8ksj {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;
  height: min-content;
  justify-content: flex-start;
  max-width: 1000px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.framer-ngLLl .framer-1pha84p {
  align-content: center;
  align-items: center;
  display: flex;
  flex: 1 0 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1px;
}
.framer-ngLLl .framer-1xmirr5 {
  -webkit-filter: grayscale(1);
  aspect-ratio: 1 / 1;
  border-radius:  0px;
  filter: grayscale(1);
  flex: none;
  height: var(--framer-aspect-ratio-supported, 80px);
  overflow: hidden;
  position: relative;
  width: 80px;
  will-change: transform;
}
.framer-ngLLl .framer-1mta33s,
.framer-ngLLl .framer-19un3sn,
.framer-ngLLl .framer-1r4ux0k {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1px;
}
.framer-ngLLl .framer-1tkavfn,
.framer-ngLLl .framer-1tbkhbu,
.framer-ngLLl .framer-1nup74,
.framer-ngLLl .framer-11fmv9f,
.framer-ngLLl .framer-omvodf,
.framer-ngLLl .framer-1i07mnv,
.framer-ngLLl .framer-1yc18fl {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-fqydua,
.framer-ngLLl .framer-1ofm1gx {
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-1vejw7u {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  max-width: 1000px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-1w5myao,
.framer-ngLLl .framer-7egsr8 {
  align-content: center;
  align-items: center;
  background-color: #a3caee;
  border-radius:  0px;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: 400px;
  justify-content: center;
  overflow: hidden;
  padding: 60px;
  position: relative;
  width: 1px;
  will-change: transform;
}
.framer-ngLLl .framer-ymorfl {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  overflow: visible;
  position: relative;
  white-space: pre;
  width: 100%;
}
.framer-ngLLl .framer-1gv355n {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 60px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-tcnceu,
.framer-ngLLl .framer-1zv4rq {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 60px;
  height: min-content;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1000px;
}
.framer-ngLLl .framer-l4fk58,
.framer-ngLLl .framer-ffcig2 {
  -webkit-filter: grayscale(1);
  aspect-ratio: 0.98 / 1;
  border-radius:  0px;
  filter: grayscale(1);
  flex: 1 0 0px;
  height: var(--framer-aspect-ratio-supported, 480px);
  mix-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  width: 1px;
  will-change: transform;
}
.framer-ngLLl .framer-1qrt7fe,
.framer-ngLLl .framer-1t0s49y {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 400px;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-epi77g,
.framer-ngLLl .framer-19oy5sw {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 60px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-xb2yix {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: flex-start;
  max-width: 1000px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-1wzeej5 {
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 100%;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 600px;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-1dre50p {
  display: grid;
  flex: none;
  gap: 20px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-template-rows: repeat(2, min-content);
  height: min-content;
  justify-content: center;
  max-width: 1000px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-fujr5h,
.framer-ngLLl .framer-uctzo5,
.framer-ngLLl .framer-15menvi,
.framer-ngLLl .framer-17f6qll {
  align-content: center;
  align-items: center;
  align-self: start;
  background-color: #a3caee;
  border-radius: 30px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: 300px;
  justify-content: center;
  justify-self: start;
  overflow: visible;
  padding: 40px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-1kffh2j,
.framer-ngLLl .framer-13dto47,
.framer-ngLLl .framer-5d6wfs,
.framer-ngLLl .framer-12wez11 {
  -webkit-filter: grayscale(1);
  aspect-ratio: 1 / 1;
  border-radius: 64px;
  filter: grayscale(1);
  flex: none;
  height: var(--framer-aspect-ratio-supported, 80px);
  overflow: hidden;
  position: relative;
  width: 80px;
  will-change: transform;
}
.framer-ngLLl .framer-kbb81,
.framer-ngLLl .framer-9hxe26,
.framer-ngLLl .framer-1hr874d,
.framer-ngLLl .framer-1lwbmlc {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-5yulu7,
.framer-ngLLl .framer-1d4gxcc,
.framer-ngLLl .framer-1c432s8,
.framer-ngLLl .framer-eel6f0,
.framer-ngLLl .framer-elvp02,
.framer-ngLLl .framer-1o4t257,
.framer-ngLLl .framer-1bynhrh,
.framer-ngLLl .framer-qgtj65 {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  overflow: visible;
  position: relative;
  white-space: pre;
  width: auto;
}
.framer-ngLLl .framer-1vc5qn7 {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 1000px;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-1pf0c8c {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 100px;
  height: min-content;
  justify-content: center;
  max-width: 1000px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-17d1wcu,
.framer-ngLLl .framer-jhx96o,
.framer-ngLLl .framer-14nugve {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: center;
  max-width: 100%;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 1px;
}
.framer-ngLLl .framer-xssg9v,
.framer-ngLLl .framer-1lbnrt4,
.framer-ngLLl .framer-1any8en {
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 400px;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-1rdql5j {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  overflow: visible;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-1e2y5oy {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 800px;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-tsri51 {
  --framer-link-text-color: #0099ff;
  --framer-link-text-decoration: underline;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  max-width: 100%;
  overflow: visible;
  position: relative;
  white-space: pre-wrap;
  width: 500px;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-p78w1h {
  align-content: center;
  align-items: center;
  background-color: #0353a4;
  border-radius: 8px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: 40px;
  justify-content: center;
  overflow: visible;
  padding: 15px;
  position: relative;
  width: min-content;
}
.framer-ngLLl .framer-1ujjqfz {
  align-content: center;
  align-items: center;
  background-color: #b9d6f2;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 100px;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-14z9fb5 {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  max-width: 1000px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-19v4aem {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: min-content;
}
.framer-ngLLl .framer-1crxpxn,
.framer-ngLLl .framer-oc9k88,
.framer-ngLLl .framer-50nt63 {
  flex: none;
  height: 32px;
  position: relative;
  width: 32px;
}
.framer-ngLLl .framer-1wmbps3 {
  flex: none;
  height: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.framer-ngLLl .framer-14pcb6k {
  --framer-link-hover-text-color: rgba(153, 153, 153, 0.6);
  --framer-link-text-decoration: none;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  overflow: hidden;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.framer-ngLLl .framer-1ww3k5s {
  --framer-link-hover-text-color: rgba(153, 153, 153, 0.6);
  --framer-link-text-decoration: none;
  --framer-paragraph-spacing: 0px;
  flex: none;
  height: auto;
  opacity: 0.5;
  overflow: hidden;
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
@supports (background: -webkit-named-image(i)) and (not (scale: 1)) {
  .framer-ngLLl .framer-72rtr7,
  .framer-ngLLl .framer-1wt6qa,
  .framer-ngLLl .framer-tuvq8b,
  .framer-ngLLl .framer-1bu57fx,
  .framer-ngLLl .framer-171542r,
  .framer-ngLLl .framer-17p6cqg,
  .framer-ngLLl .framer-1pfrn1h,
  .framer-ngLLl .framer-13xutd,
  .framer-ngLLl .framer-ql8ksj,
  .framer-ngLLl .framer-1pha84p,
  .framer-ngLLl .framer-1mta33s,
  .framer-ngLLl .framer-6q8iyr,
  .framer-ngLLl .framer-1vejw7u,
  .framer-ngLLl .framer-1w5myao,
  .framer-ngLLl .framer-7egsr8,
  .framer-ngLLl .framer-1gv355n,
  .framer-ngLLl .framer-tcnceu,
  .framer-ngLLl .framer-19un3sn,
  .framer-ngLLl .framer-1zv4rq,
  .framer-ngLLl .framer-1r4ux0k,
  .framer-ngLLl .framer-epi77g,
  .framer-ngLLl .framer-xb2yix,
  .framer-ngLLl .framer-fujr5h,
  .framer-ngLLl .framer-kbb81,
  .framer-ngLLl .framer-uctzo5,
  .framer-ngLLl .framer-9hxe26,
  .framer-ngLLl .framer-15menvi,
  .framer-ngLLl .framer-1hr874d,
  .framer-ngLLl .framer-17f6qll,
  .framer-ngLLl .framer-1lwbmlc,
  .framer-ngLLl .framer-19oy5sw,
  .framer-ngLLl .framer-1pf0c8c,
  .framer-ngLLl .framer-17d1wcu,
  .framer-ngLLl .framer-jhx96o,
  .framer-ngLLl .framer-14nugve,
  .framer-ngLLl .framer-1rdql5j,
  .framer-ngLLl .framer-p78w1h,
  .framer-ngLLl .framer-1ujjqfz,
  .framer-ngLLl .framer-14z9fb5,
  .framer-ngLLl .framer-19v4aem {
    gap: 0px;
  }
  .framer-ngLLl .framer-72rtr7 > *,
  .framer-ngLLl .framer-13xutd > *,
  .framer-ngLLl .framer-6q8iyr > *,
  .framer-ngLLl .framer-14z9fb5 > * {
    margin: 0;
  }
  .framer-ngLLl .framer-72rtr7 > :first-child,
  .framer-ngLLl .framer-1wt6qa > :first-child,
  .framer-ngLLl .framer-171542r > :first-child,
  .framer-ngLLl .framer-17p6cqg > :first-child,
  .framer-ngLLl .framer-1pfrn1h > :first-child,
  .framer-ngLLl .framer-13xutd > :first-child,
  .framer-ngLLl .framer-1mta33s > :first-child,
  .framer-ngLLl .framer-6q8iyr > :first-child,
  .framer-ngLLl .framer-1w5myao > :first-child,
  .framer-ngLLl .framer-7egsr8 > :first-child,
  .framer-ngLLl .framer-1gv355n > :first-child,
  .framer-ngLLl .framer-19un3sn > :first-child,
  .framer-ngLLl .framer-1r4ux0k > :first-child,
  .framer-ngLLl .framer-epi77g > :first-child,
  .framer-ngLLl .framer-xb2yix > :first-child,
  .framer-ngLLl .framer-fujr5h > :first-child,
  .framer-ngLLl .framer-kbb81 > :first-child,
  .framer-ngLLl .framer-uctzo5 > :first-child,
  .framer-ngLLl .framer-9hxe26 > :first-child,
  .framer-ngLLl .framer-15menvi > :first-child,
  .framer-ngLLl .framer-1hr874d > :first-child,
  .framer-ngLLl .framer-17f6qll > :first-child,
  .framer-ngLLl .framer-1lwbmlc > :first-child,
  .framer-ngLLl .framer-19oy5sw > :first-child,
  .framer-ngLLl .framer-17d1wcu > :first-child,
  .framer-ngLLl .framer-jhx96o > :first-child,
  .framer-ngLLl .framer-14nugve > :first-child,
  .framer-ngLLl .framer-1rdql5j > :first-child,
  .framer-ngLLl .framer-1ujjqfz > :first-child,
  .framer-ngLLl .framer-14z9fb5 > :first-child {
    margin-top: 0;
  }
  .framer-ngLLl .framer-72rtr7 > :last-child,
  .framer-ngLLl .framer-1wt6qa > :last-child,
  .framer-ngLLl .framer-171542r > :last-child,
  .framer-ngLLl .framer-17p6cqg > :last-child,
  .framer-ngLLl .framer-1pfrn1h > :last-child,
  .framer-ngLLl .framer-13xutd > :last-child,
  .framer-ngLLl .framer-1mta33s > :last-child,
  .framer-ngLLl .framer-6q8iyr > :last-child,
  .framer-ngLLl .framer-1w5myao > :last-child,
  .framer-ngLLl .framer-7egsr8 > :last-child,
  .framer-ngLLl .framer-1gv355n > :last-child,
  .framer-ngLLl .framer-19un3sn > :last-child,
  .framer-ngLLl .framer-1r4ux0k > :last-child,
  .framer-ngLLl .framer-epi77g > :last-child,
  .framer-ngLLl .framer-xb2yix > :last-child,
  .framer-ngLLl .framer-fujr5h > :last-child,
  .framer-ngLLl .framer-kbb81 > :last-child,
  .framer-ngLLl .framer-uctzo5 > :last-child,
  .framer-ngLLl .framer-9hxe26 > :last-child,
  .framer-ngLLl .framer-15menvi > :last-child,
  .framer-ngLLl .framer-1hr874d > :last-child,
  .framer-ngLLl .framer-17f6qll > :last-child,
  .framer-ngLLl .framer-1lwbmlc > :last-child,
  .framer-ngLLl .framer-19oy5sw > :last-child,
  .framer-ngLLl .framer-17d1wcu > :last-child,
  .framer-ngLLl .framer-jhx96o > :last-child,
  .framer-ngLLl .framer-14nugve > :last-child,
  .framer-ngLLl .framer-1rdql5j > :last-child,
  .framer-ngLLl .framer-1ujjqfz > :last-child,
  .framer-ngLLl .framer-14z9fb5 > :last-child {
    margin-bottom: 0;
  }
  .framer-ngLLl .framer-1wt6qa > * {
    margin: 50px 0;
  }
  .framer-ngLLl .framer-tuvq8b > *,
  .framer-ngLLl .framer-1vejw7u > *,
  .framer-ngLLl .framer-19v4aem > * {
    margin: 0 10px;
  }
  .framer-ngLLl .framer-tuvq8b > :first-child,
  .framer-ngLLl .framer-1bu57fx > :first-child,
  .framer-ngLLl .framer-ql8ksj > :first-child,
  .framer-ngLLl .framer-1pha84p > :first-child,
  .framer-ngLLl .framer-1vejw7u > :first-child,
  .framer-ngLLl .framer-tcnceu > :first-child,
  .framer-ngLLl .framer-1zv4rq > :first-child,
  .framer-ngLLl .framer-1pf0c8c > :first-child,
  .framer-ngLLl .framer-p78w1h > :first-child,
  .framer-ngLLl .framer-19v4aem > :first-child {
    margin-left: 0;
  }
  .framer-ngLLl .framer-tuvq8b > :last-child,
  .framer-ngLLl .framer-1bu57fx > :last-child,
  .framer-ngLLl .framer-ql8ksj > :last-child,
  .framer-ngLLl .framer-1pha84p > :last-child,
  .framer-ngLLl .framer-1vejw7u > :last-child,
  .framer-ngLLl .framer-tcnceu > :last-child,
  .framer-ngLLl .framer-1zv4rq > :last-child,
  .framer-ngLLl .framer-1pf0c8c > :last-child,
  .framer-ngLLl .framer-p78w1h > :last-child,
  .framer-ngLLl .framer-19v4aem > :last-child {
    margin-right: 0;
  }
  .framer-ngLLl .framer-1bu57fx > *,
  .framer-ngLLl .framer-1pha84p > *,
  .framer-ngLLl .framer-p78w1h > * {
    margin: 0 5px;
  }
  .framer-ngLLl .framer-171542r > *,
  .framer-ngLLl .framer-17p6cqg > *,
  .framer-ngLLl .framer-1pfrn1h > *,
  .framer-ngLLl .framer-kbb81 > *,
  .framer-ngLLl .framer-9hxe26 > *,
  .framer-ngLLl .framer-1hr874d > *,
  .framer-ngLLl .framer-1lwbmlc > *,
  .framer-ngLLl .framer-17d1wcu > *,
  .framer-ngLLl .framer-jhx96o > *,
  .framer-ngLLl .framer-14nugve > *,
  .framer-ngLLl .framer-1ujjqfz > * {
    margin: 5px 0;
  }
  .framer-ngLLl .framer-ql8ksj > * {
    margin: 0 20px;
  }
  .framer-ngLLl .framer-1mta33s > *,
  .framer-ngLLl .framer-1w5myao > *,
  .framer-ngLLl .framer-7egsr8 > *,
  .framer-ngLLl .framer-19un3sn > *,
  .framer-ngLLl .framer-1r4ux0k > *,
  .framer-ngLLl .framer-xb2yix > *,
  .framer-ngLLl .framer-fujr5h > *,
  .framer-ngLLl .framer-uctzo5 > *,
  .framer-ngLLl .framer-15menvi > *,
  .framer-ngLLl .framer-17f6qll > *,
  .framer-ngLLl .framer-1rdql5j > * {
    margin: 10px 0;
  }
  .framer-ngLLl .framer-1gv355n > *,
  .framer-ngLLl .framer-epi77g > *,
  .framer-ngLLl .framer-19oy5sw > * {
    margin: 30px 0;
  }
  .framer-ngLLl .framer-tcnceu > *,
  .framer-ngLLl .framer-1zv4rq > * {
    margin: 0 30px;
  }
  .framer-ngLLl .framer-1pf0c8c > * {
    margin: 0 50px;
  }
}
@media (min-width: 1200px) {
  .framer-ngLLl .hidden-72rtr7 {
    display: none !important;
  }
}
@media (min-width: 810px) and (max-width: 1199px) {
  .framer-ngLLl .hidden-4geq0r {
    display: none !important;
  }
  .framer-body-augiA20Il {
    background: #b9d6f2;
  }
  .framer-ngLLl .framer-72rtr7 {
    width: 810px;
  }
  .framer-ngLLl .framer-1wt6qa,
  .framer-ngLLl .framer-epi77g,
  .framer-ngLLl .framer-19oy5sw,
  .framer-ngLLl .framer-1ujjqfz {
    padding: 50px;
  }
  .framer-ngLLl .framer-171542r,
  .framer-ngLLl .framer-17p6cqg,
  .framer-ngLLl .framer-1pfrn1h {
    height: var(--framer-aspect-ratio-supported, 230px);
  }
  .framer-ngLLl .framer-13xutd {
    padding: 80px 40px;
  }
  .framer-ngLLl .framer-6q8iyr {
    align-content: flex-start;
    align-items: flex-start;
    padding: 80px 40px;
  }
  .framer-ngLLl .framer-1w5myao {
    align-self: stretch;
    border-radius: 30px;
    height: auto;
  }
  .framer-ngLLl .framer-7egsr8 {
    border-radius: 30px;
    height: min-content;
  }
  .framer-ngLLl .framer-1gv355n {
    gap: 40px;
    padding: 50px;
  }
  .framer-ngLLl .framer-l4fk58 {
    border-radius: 30px;
    height: var(--framer-aspect-ratio-supported, 332px);
  }
  .framer-ngLLl .framer-ffcig2 {
    border-radius: 30px;
    height: var(--framer-aspect-ratio-supported, 331px);
  }
  .framer-ngLLl .framer-1pf0c8c {
    gap: 60px;
  }
  .framer-ngLLl .framer-1rdql5j {
    padding: 100px 50px;
  }
  @supports (background: -webkit-named-image(i)) and (not (scale: 1)) {
    .framer-ngLLl .framer-1gv355n,
    .framer-ngLLl .framer-1pf0c8c {
      gap: 0px;
    }
    .framer-ngLLl .framer-1gv355n > * {
      margin: 20px 0;
    }
    .framer-ngLLl .framer-1gv355n > :first-child {
      margin-top: 0;
    }
    .framer-ngLLl .framer-1gv355n > :last-child {
      margin-bottom: 0;
    }
    .framer-ngLLl .framer-1pf0c8c > * {
      margin: 0 30px;
    }
    .framer-ngLLl .framer-1pf0c8c > :first-child {
      margin-left: 0;
    }
    .framer-ngLLl .framer-1pf0c8c > :last-child {
      margin-right: 0;
    }
  }
}
@media (max-width: 809px) {
  .framer-ngLLl .hidden-1fb1oo7 {
    display: none !important;
  }
  .framer-body-augiA20Il {
    background: #b9d6f2;
  }
  .framer-ngLLl .framer-72rtr7 {
    width: 390px;
  }
  .framer-ngLLl .framer-1wt6qa {
    gap: 20px;
    justify-content: center;
    padding: 60px 20px 20px;
  }
  .framer-ngLLl .framer-tuvq8b {
    flex-direction: column;
    order: 0;
  }
  .framer-ngLLl .framer-1c2ak4l,
  .framer-ngLLl .framer-1pha84p,
  .framer-ngLLl .framer-1mta33s,
  .framer-ngLLl .framer-17d1wcu,
  .framer-ngLLl .framer-jhx96o,
  .framer-ngLLl .framer-14nugve {
    flex: none;
    width: 100%;
  }
  .framer-ngLLl .framer-1bu57fx {
    order: 1;
  }
  .framer-ngLLl .framer-171542r,
  .framer-ngLLl .framer-17p6cqg,
  .framer-ngLLl .framer-1pfrn1h {
    height: var(--framer-aspect-ratio-supported, 110px);
  }
  .framer-ngLLl .framer-13xutd,
  .framer-ngLLl .framer-1gv355n,
  .framer-ngLLl .framer-1rdql5j {
    padding: 60px 20px;
  }
  .framer-ngLLl .framer-ql8ksj {
    flex-direction: column;
    gap: 20px;
  }
  .framer-ngLLl .framer-1xmirr5 {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: var(--framer-aspect-ratio-supported, 60px);
    justify-content: center;
    padding: 0;
    width: 60px;
  }
  .framer-ngLLl .framer-6q8iyr {
    padding: 20px;
  }
  .framer-ngLLl .framer-1vejw7u {
    flex-direction: column;
  }
  .framer-ngLLl .framer-1w5myao,
  .framer-ngLLl .framer-7egsr8 {
    border-radius: 20px;
    flex: none;
    padding: 40px;
    width: 100%;
  }
  .framer-ngLLl .framer-tcnceu,
  .framer-ngLLl .framer-1zv4rq,
  .framer-ngLLl .framer-1pf0c8c {
    flex-direction: column;
    gap: 40px;
  }
  .framer-ngLLl .framer-l4fk58 {
    border-radius: 20px;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 357px);
    order: 0;
    width: 100%;
  }
  .framer-ngLLl .framer-19un3sn,
  .framer-ngLLl .framer-1r4ux0k {
    flex: none;
    order: 1;
    width: 100%;
  }
  .framer-ngLLl .framer-ffcig2 {
    border-radius: 20px;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 358px);
    order: 0;
    width: 100%;
  }
  .framer-ngLLl .framer-epi77g {
    gap: 40px;
    padding: 60px 20px;
  }
  .framer-ngLLl .framer-1dre50p {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
  .framer-ngLLl .framer-19oy5sw {
    align-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    padding: 20px;
  }
  .framer-ngLLl .framer-p78w1h {
    width: 100%;
  }
  .framer-ngLLl .framer-1ujjqfz {
    padding: 40px 20px;
  }
  @supports (background: -webkit-named-image(i)) and (not (scale: 1)) {
    .framer-ngLLl .framer-1wt6qa,
    .framer-ngLLl .framer-tuvq8b,
    .framer-ngLLl .framer-ql8ksj,
    .framer-ngLLl .framer-1xmirr5,
    .framer-ngLLl .framer-1vejw7u,
    .framer-ngLLl .framer-tcnceu,
    .framer-ngLLl .framer-1zv4rq,
    .framer-ngLLl .framer-epi77g,
    .framer-ngLLl .framer-19oy5sw,
    .framer-ngLLl .framer-1pf0c8c {
      gap: 0px;
    }
    .framer-ngLLl .framer-1wt6qa > *,
    .framer-ngLLl .framer-tuvq8b > *,
    .framer-ngLLl .framer-ql8ksj > *,
    .framer-ngLLl .framer-1vejw7u > * {
      margin: 10px 0;
    }
    .framer-ngLLl .framer-1wt6qa > :first-child,
    .framer-ngLLl .framer-tuvq8b > :first-child,
    .framer-ngLLl .framer-ql8ksj > :first-child,
    .framer-ngLLl .framer-1xmirr5 > :first-child,
    .framer-ngLLl .framer-1vejw7u > :first-child,
    .framer-ngLLl .framer-tcnceu > :first-child,
    .framer-ngLLl .framer-1zv4rq > :first-child,
    .framer-ngLLl .framer-epi77g > :first-child,
    .framer-ngLLl .framer-19oy5sw > :first-child,
    .framer-ngLLl .framer-1pf0c8c > :first-child {
      margin-top: 0;
    }
    .framer-ngLLl .framer-1wt6qa > :last-child,
    .framer-ngLLl .framer-tuvq8b > :last-child,
    .framer-ngLLl .framer-ql8ksj > :last-child,
    .framer-ngLLl .framer-1xmirr5 > :last-child,
    .framer-ngLLl .framer-1vejw7u > :last-child,
    .framer-ngLLl .framer-tcnceu > :last-child,
    .framer-ngLLl .framer-1zv4rq > :last-child,
    .framer-ngLLl .framer-epi77g > :last-child,
    .framer-ngLLl .framer-19oy5sw > :last-child,
    .framer-ngLLl .framer-1pf0c8c > :last-child {
      margin-bottom: 0;
    }
    .framer-ngLLl .framer-1xmirr5 > * {
      margin: 0;
    }
    .framer-ngLLl .framer-tcnceu > *,
    .framer-ngLLl .framer-1zv4rq > *,
    .framer-ngLLl .framer-epi77g > *,
    .framer-ngLLl .framer-19oy5sw > *,
    .framer-ngLLl .framer-1pf0c8c > * {
      margin: 20px 0;
    }
  }
}
.ssr-variant {
  display: contents;
}
